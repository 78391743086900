<template>
  <el-dialog
    title="上传文件"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="uploadVisible"
    width="760px"
    @close="handleClose"
  >
    <el-upload
      ref="upload"
      :action="uploadApi"
      :headers="uploadHeaders"
      :data="{appBucketName: 'traffic-construction'}"
      :show-file-list="false"
      :auto-upload="false"
      :file-list="fileList"
      :on-success="onSuccess"
      :on-error="onError"
      :on-change="onChange"
      multiple
    >
      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
    </el-upload>
    <!-- <p>待上传列表</p> -->
    <el-table :data="fileList" size="small" max-height="500">
      <el-table-column align="center" type="index" label="序号"></el-table-column>
      <el-table-column align="center" prop="name" label="文件名"></el-table-column>
      <!-- <el-table-column align="center" label="上传状态" width="120">
        <template slot-scope="{ row }">{{row.status}}</template>
      </el-table-column>-->
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="{ row }">
          <el-button type="text" size="small" @click="handleRemove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- footer -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="onConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import { addFileToFolder } from '@/api/general-user/internal-data.js';

export default {
  name: 'UploadDialog',
  props: {
    folderId: { type: String }
  },
  data() {
    return {
      uploadVisible: false,
      uploadApi: '/api/roses-pub-service/sysFileInfo/uploadResponse',
      token: '',
      tenantId: '',
      fileList: [],
      confirmLoading: false,
      successFileIdList: []
    };
  },
  created() {
    this.token = getItem('token');
    this.tenantId = getItem('tenantInfo').tenantId;
  },
  computed: {
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.token,
        AppCode: 'traffic-construction',
        tenantId: this.tenantId
      };
    }
  },
  methods: {
    open() {
      this.uploadVisible = true;
    },
    handleClose() {
      this.uploadVisible = false;
      this.fileList = [];
      this.successFileIdList = [];
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(row) {
      this.fileList = this.fileList.filter(item => item.uid !== row.uid);
    },
    onSuccess(res, file, fileList) {
      this.successFileIdList.push(res.data.fileId);

      if (this.successFileIdList.length === fileList.length) {
        /* 全部上传完成 */
        this.uploadToFolder(this.successFileIdList);
      }
    },
    uploadToFolder(fileIds) {
      addFileToFolder({ fileIds, folderId: this.folderId })
        .then(res => {
          this.$message.success('文件上传成功');
          this.$emit('add-success', this.folderId);
          this.handleClose();
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onError(res, file, fileList) {
      this.confirmLoading = false;
    },
    onConfirm() {
      this.confirmLoading = true;
      this.$refs.upload.submit();
    }
  }
};
</script>
