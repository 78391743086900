<template>
  <el-dialog
    width="530px"
    title="文件详情"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="80px" :model="paramsForm">
      <el-form-item label="文件名" prop="fileName">
        <el-input disabled v-model="paramsForm.fileName" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="上传人" prop="createUserName">
        <el-input disabled v-model="paramsForm.createUserName" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="所属机构" prop="orgName">
        <el-input disabled v-model="paramsForm.orgName" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="上传时间" prop="createTime">
        <el-input disabled v-model="paramsForm.createTime" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="文件大小" prop="fileSizeMb">
        <el-input disabled v-model="paramsForm.fileSizeMb" style="width: 400px"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      paramsForm: {
        fileName: '',
        createUserName: '',
        orgName: '',
        createTime: '',
        fileSizeMb: ''
      }
    };
  },
  methods: {
    open(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.paramsForm.fileName = row.fileName;
        this.paramsForm.createUserName = row.createUserName;
        this.paramsForm.orgName = row.orgName;
        this.paramsForm.createTime = row.createTime;
        this.paramsForm.fileSizeMb = row.fileSizeMb;
      });
    },
    handleColse() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    }
  }
};
</script>
