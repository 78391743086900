<template>
  <div>
    <el-row :gutter="40" v-if="fileList.length">
      <el-col
        v-for="(item,index) in fileList"
        :key="item.fileType === 1 ? item.id : item.fileId"
        :xl="3"
        :lg="4"
        :md="6"
        :sm="8"
      >
        <el-dropdown ref="dropdown" trigger="click" :disabled="item.fileType === 1">
          <div
            class="file-item"
            @dblclick="onDblClick(item)"
            @contextmenu="onContextmenu($event,item,index)"
          >
            <span v-if="item.fileType === 1" class="file-content">
              <i class="icon-image"></i>
              <el-tooltip
                effect="dark"
                :enterable="false"
                :content="item.folderName"
                placement="top"
              >
                <span class="file-name">{{item.folderName}}</span>
              </el-tooltip>
            </span>
            <span v-else-if="item.fileType === 2" class="file-content">
              <i class="icon-image file-icon"></i>
              <el-tooltip effect="dark" :enterable="false" :content="item.fileName" placement="top">
                <span class="file-name">{{item.fileName}}</span>
              </el-tooltip>
              <p class="file-size">{{ item.fileSizeMb }}</p>
            </span>
          </div>
          <!-- 下拉菜单 -->
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="$check('data-list_download')"
              @click.native="handleDownload(item)"
            >下载文件</el-dropdown-item>
            <el-dropdown-item @click.native="handleDetail(item)">查看详情</el-dropdown-item>
            <el-dropdown-item
              v-if="$check('data-list_delete')"
              style="color: #F56C6C"
              @click.native="handleDelete(item)"
            >删除文件</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-empty v-else description="此文件夹为空。"></el-empty>
    <!-- 详情 -->
    <detail-dialog ref="detailDialogRef"></detail-dialog>
  </div>
</template>

<script>
import downloadFileFunc from '@/utils/download-file.js';

import DetailDialog from '@/views/pages/general-user/security-pages/internal-data/data-list/detailDialog.vue';
import { deleteFile, downloadFile } from '@/api/general-user/internal-data.js';

export default {
  name: 'FileContentFlat',
  components: { DetailDialog },
  props: {
    folderId: { type: String },
    fileList: { type: Array, default: () => [] }
  },
  data() {
    return {
      currentId: ''
    };
  },
  methods: {
    // 鼠标双击事件
    onDblClick(item) {
      if (item.fileType === 1) {
        this.$emit('on-dblclick', item);
      }
    },
    // 鼠标右击事件
    onContextmenu(e, item, index) {
      e.preventDefault();
      if (item.fileType === 2) {
        this.$refs.dropdown[index].show();
      }
    },
    handleDelete(item) {
      this.$confirm('是否确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            folderId: '',
            fileIds: []
          };
          params.folderId = this.folderId;
          params.fileIds.push(item.fileId);
          return deleteFile(params);
        })
        .then(res => {
          this.$message.success('删除文件成功！');
          this.$emit('on-refresh');
        });
    },
    handleDetail(item) {
      this.$refs.detailDialogRef.open(item);
    },
    handleDownload(item) {
      downloadFile({ fileId: item.fileId }).then(res => {
        downloadFileFunc(res);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.file-item {
  user-select: none;
  box-sizing: border-box;
  margin-top: 43px;
  width: 136px;
  height: 146px;
  background: #ffffff;
  border: 1px solid #dcdfe7;
  border-radius: 4px;
  text-align: center;
  padding: 16px 8px;
  cursor: pointer;

  &:hover {
    background-color: #f5f7fa;
  }

  p {
    margin: 0;
  }
  // [class*='el-icon-'] {
  //   font-size: 64px;
  // }
  .file-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .file-name {
      display: inline-block;
      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 8px 0 4px;
      font-size: 14px;
      color: #303133;
    }

    .file-size {
      font-size: 12px;
      color: #979fa8;
    }
  }
}

.active {
  background-color: #f5f7fa;
}

.icon-image {
  display: inline-block;
  width: 67px;
  height: 67px;
  // background-color: pink;
  background: url('~@/assets/images/folder.png') no-repeat;
  background-size: cover;
}

.file-icon {
  background: url('~@/assets/images/file.png') no-repeat;
  background-size: cover;
}
</style>
